<template>
  <div :class="propsClassName">

    <content-loading type="app" v-if="isLoading"/>
    <div class="fromwhered_chackbox" v-else-if="options && options.length">
      <h4>{{$t("sabre.search-panel.top-destinations")}} </h4>
      <div class="row g-3 row-cols-3">
        <div class="form-check col" v-for="(item, inx) in options" :key="inx" @click="() => selectItem(item.value)">
          <input class="form-check-input" type="checkbox"
          :checked="storedData.includes(item.value)">
          <label class="form-check-label" >{{item.label}}</label>
        </div>
      </div>
    </div>

    <button class="cleanup_close" v-if="device==='mobile'" @click="() => save()"><i class="fas fa-times"></i></button>

    <div class="btn-group-area d-flex justify-content-end">
      <button class="cleanup_btn" @click="() => selectItem()">{{$t("sabre.buttons.clean")}}</button>
      <button class="save_btn" @click="() => save()">{{$t("sabre.buttons.save")}}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'select-subject-months',
  props: {
    propsClassName: {
      type: String,
      default: '',
    },
    category: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    componentName: {
      type: String,
      default: '',
    },
  },
  components: {
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      isLoading: 'GET_DATE_LOADING_STATE',
      storedMonths: 'GET_FH_YEAR_MONTHS',
    }),
    storedData() {
      const { storedMonths } = this;
      if (!storedMonths) return [];
      return storedMonths;
    },
  },
  data() {
    return {
    };
  },
  methods: {
    selectItem(code) {
      const { isMultiple, storedData } = this;
      const inx = storedData.findIndex((c) => c === code);

      let storeCodes = [...storedData];
      if (!code) {
        storeCodes = [];
      } else if (inx > -1) {
        storeCodes.splice(inx, 1);
      } else if (isMultiple) {
        storeCodes.push(code);
      } else {
        storeCodes = [code];
      }

      this.$store.commit('SET_FH_YEAR_MONTHS', storeCodes);
    },
    save() {
      this.$emit('finished');
    },
  },
};
</script>
